require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery") // 追記
require("util")
//追記
require("chartkick")
require("chart.js")

// 追記
import 'bootstrap'
import '../src/application.scss'
import 'chartkick/chart.js'